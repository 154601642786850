<template>
  <div id="modalCompanion" class="companion-redesign-content">
    <b-loading :active.sync="isLoading" :can-cancel="false" :is-full-page="false"></b-loading>
    <i
      class="icon-mks icon_close"
      @click="closePopUp"
    ></i>
    <div class="companion-redesign-content__title">
      <i class="icon-mks icon_companion"></i>
      <h2>
        Acompañantes
      </h2>
    </div>
    <div class="companion-redesign-content__table" v-if="dataCompanion.length > 0">
      <div class="companion-redesign-content__table__header">
        <div>Nombre</div>
        <div>Tipo doc</div>
        <div>Número</div>
        <div></div>
      </div>
      <div class="companion-redesign-content__table__body">
        <div class="companion-redesign-content__table__body__row" v-for="(comp, index) in dataCompanion" :key="index">
          <div class="one-line-ellipsis">{{comp.name}}</div>
          <div>{{comp.typeDocument}}</div>
          <div class="one-line-ellipsis">{{comp.documentIdentify}}</div>
          <div>
            <i
              @click="deleteCompanion(comp.id)"
              class="fa fa-trash"
            ></i>
            <i
              @click="setEditDataCompanion(comp)"
              class="fa fa-pencil-square-o"
            ></i>
          </div>
        </div>
      </div>
    </div>
    <div class="companion-redesign-content__create-edit edit" v-if="add || edit">
      <div class="forms" id="editCompanion">
        <div class="formulario-edit">
          <div class="cont-form wrap">
            <div class="input-edit">
              <span class="text gris size-10 bold-600 margin-r-10 upliner">Nombre*</span>
                <!-- @input="searchUser(companion.name)" -->
              <input
                class="inp"
                name="name_companion"
                type="text"
                v-model="companion.name"
                v-show="add"
                v-validate="'min:2|required'"
              />
              <i aria-hidden="true" class="fa fa-user-o"></i>
              <span
                class="text size-9 red error"
                v-if="errors.has('name_companion')"
              >{{ errors.first('name_companion') }}</span>
            </div>
          </div>
          <div class="cont-form wrap">
            <div class="input-edit input-edit__select" style="margin-right: 0;">
              <span class="text gris size-10 bold-600 margin-r-10 upliner">Tipo doc</span>
              <select class="inp-select" v-show="add" name="" id="" v-model="companion.typeDocument">
                <option v-for="(type, index) in typeIdentify" :key="index" :value="type.value">{{type.label}}</option>
              </select>
              <i aria-hidden="true" class="fa fa-id-card-o"></i>
              <span
                class="text size-9 red error"
                v-if="errors.has('type_document_companion')"
              >{{ errors.first('type_document_companion') }}</span>
            </div>
            <div class="input-edit" style="margin-right: 0;">
              <span class="text gris size-10 bold-600 margin-r-10 upliner">Identificación*</span>
              <input
                class="inp"
                name="identification_companion"
                type="text"
                v-model="companion.documentIdentify"
                v-show="add"
                v-validate="'required|regex:^[a-zA-Z0-9]*$|min:5'"
              />
              <i aria-hidden="true" class="fa fa-id-card-o"></i>
              <span
                class="text size-9 red error"
                v-if="errors.has('identification_companion')"
              >{{ errors.first('identification_companion') }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="companion-redesign-content__create-edit__btns">
        <div @click="() => {
            add = false;
            edit = false;
          }" class="btn-companion">
          <span class="text color-btn size-14">
            <i class="fa fa-times"></i> Cancelar
          </span>
        </div>
        <div @click="addCompanion(companion)" class="btn-companion">
          <span class="text color-btn size-14">
            <i class="fa fa-save"></i> Guardar
          </span>
        </div>
      </div>
    </div>
    <b-button class="companion-redesign-content__table--add-companion" @click="createCompanion" v-if="!add && dataCompanion.length < count">Agregar acompañantes</b-button>
  </div>
</template>
<script>
import BanderaPais from 'country-json/src/country-by-abbreviation.json';
import callingCode from 'country-json/src/country-by-calling-code.json';
/* import Multiselect from 'vue-multiselect'; */
import _ from 'lodash';

export default {
  name: 'CompanionMain',
  props: ['infoReserva'],
  components: {
  },
  data () {
    return {
      isLoading: false,
      dataCompanion: [],
      companion: {
        name: null,
        email: null,
        phone: null,
        indicative: null,
        reservationId: null,
        documentIdentify: null,
        typeDocument: 'CC',
        temperature: 0,
        address: null,
        question1: null,
        question2: null
      },
      validateTel: '',
      option: {
        value: 57,
        label: 'Colombia ( +57 )',
        label_show: '+57',
        selected: true
      },
      typeIdentify: [
        { label: 'Cédula de ciudadania', value: 'CC' },
        { label: 'Cédula de extranjeria', value: 'CE' },
        { label: 'Registro civil', value: 'RC' },
        { label: 'Número de identificación tributario', value: 'NIT' }
      ],
      showUl: false,
      add: false,
      edit: false,
      count: 0,
      paises: [],
      bandera: null,
      pais: null
    };
  },
  mounted () {
    this.listCompanion(this.infoReserva.id);
    this.count = this.infoReserva.people - 1;
    this.listPaises();
    /* const indicativo = this.$store.getters['global:vendor'].params.indicativeRegion;
    this.indicativeParam(indicativo);
    this.changedPais(this.option); */
  },
  methods: {
    closePopUp () {
      if (this.infoReserva.id === undefined || this.infoReserva.reservationId === undefined) {
        this.$emit('updateCompanion', this.data);
      }
      this.$parent.close();
    },
    indicativeParam (indicativo) {
      const selectIndicative = this.paises.find(value => parseInt(value.value) === parseInt(indicativo));
      this.option = selectIndicative;
    },
    listPaises () {
      var data = [];
      var ind = this.companion.indicative != null ? this.companion.indicative : null;
      _.forEach(callingCode, function (item) {
        if (item.calling_code !== null) {
          if (ind != null && ind === item.calling_code && item.country !== 'Bouvet Island') {
            data.push({
              value: item.calling_code,
              label: item.country + ' ( +' + item.calling_code + ' )',
              label_show: '+' + item.calling_code,
              selected: true
            });
          } else if (item.country !== 'Bouvet Island') {
            data.push({
              value: item.calling_code,
              label: item.country + ' ( +' + item.calling_code + ' )',
              label_show: item.country + ' ( +' + item.calling_code + ' )'
            });
          }
        }
      });
      this.paises = data;
    },
    changedPais (indicativo) {
      this.option = indicativo;
      let aux2 = null;
      const aux3 = indicativo.label;
      this.pais = indicativo.label;
      this.companion.indicativo = indicativo.value;
      const ind = indicativo.value;
      _.forEach(BanderaPais, function (item) {
        if (ind !== null) {
          const data = item.country + ' ( +' + ind + ' )';
          if (data === aux3) {
            aux2 = item.abbreviation.toLowerCase();
          }
        }
      });
      this.bandera =
        aux2 != null ? 'https://api.precompro.com/banderas/' + aux2 + '.png' : null;
      this.option.label_show = '+' + ind;
      this.companion.indicative = '+' + ind;
      switch (indicativo.value) {
        case 57:
          this.validateTel = 'required|numeric|min:10|max:10';
          break;
        case 507:
          this.validateTel = 'required|numeric|min:7|max:8';
          break;
        default:
          this.validateTel = 'required|numeric|min:7|max:11';
          break;
      }
    },
    listCompanion (id) {
      this.isLoading = true;
      this.$store.dispatch({ type: 'reservation:companionList', data: { id } }).then(({ data }) => {
        this.dataCompanion = data.companion;
        this.isLoading = false;
      }).catch((err) => {
        console.error(err);
        this.isLoading = false;
      });
    },
    createCompanion () {
      this.add = true;
      this.setEditDataCompanion();
    },
    addCompanion (data) {
      if (this.edit === false) {
        this.$validator.validateAll().then(result => {
          if (result) {
            this.isLoading = true;
            data.reservationId = this.infoReserva.id;
            this.$store.dispatch({
              type: 'reservation:companions', data
            }).then((data) => {
              this.listCompanion(this.infoReserva.id);
              /* this.modeEdit('add', null); */
              this.add = false;
              this.isLoading = false;
            }).catch(err => {
              console.error(err);
              this.isLoading = false;
            });
          }
        });
      } else if (this.edit === true) {
        this.editCompanion(data);
      }
    },
    editCompanion (data) {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.isLoading = true;
          const dataCompa = JSON.parse(JSON.stringify(data));
          this.$store.dispatch({
            type: 'reservation:companionUpdate', data: dataCompa
          }).then(() => {
            this.add = false;
            this.edit = false;
            this.listCompanion(this.infoReserva.id);
            this.isLoading = false;
          }).catch(err => {
            console.error(err);
            this.isLoading = false;
          });
        }
      });
    },
    deleteCompanion (id) {
      this.$buefy.dialog.confirm({
        title: 'Eliminar acompañante',
        message: '¿Estás seguro de que deseas <b>eliminar</b> tu acompañante? Esta acción no se puede deshacer.',
        confirmText: 'Si, Eliminar',
        cancelText: 'No',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => {
          this.isLoading = true;
          this.$store.dispatch({ type: 'reservation:companionDelete', data: { id } }).then(() => {
            this.listCompanion(this.infoReserva.id);
            this.isLoading = false;
          }).catch(() => {
            this.isLoading = false;
          });
        }
      });
    },
    setEditDataCompanion (data) {
      this.errors.clear();
      this.companion = {
        name: null,
        email: null,
        phone: null,
        indicative: null,
        reservationId: null,
        documentIdentify: null,
        typeDocument: 'CC',
        temperature: 0,
        address: null,
        question1: null,
        question2: null
      };
      if (this.edit) {
        this.companion = JSON.parse(JSON.stringify(data));
      } else {
        this.companion = JSON.parse(JSON.stringify(data));
        this.add = true;
        this.edit = true;
      }
    },
    searchUser (string) {
      clearTimeout(this.timeout);
      const thes = this;
      this.isLoading = true;
      this.timeout = setTimeout(function () {
        if (string !== '' && string.length >= 3) {
          thes.$store
            .dispatch({
              type: 'people:search',
              data: { search: string, vendorId: thes.vendor.id }
            })
            .then(response => {
              thes.dataUser = response;
              thes.showUl = true;
              thes.isLoading = false;
            }).catch(() => {
              thes.isLoading = false;
            });
        } else {
          thes.isLoading = false;
        }
      }, 500);
    }
  }
};
</script>

<style lang="scss">
  @use "@/assets/styles/companion/_index.scss";
</style>
